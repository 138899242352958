import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import {tap} from 'rxjs/operators';

import {MposConfirmDialogComponent} from 'app/common/dialog/confirm-dialog/confirm-dialog.component';
import {MposMessageDialogComponent} from 'app/common/dialog/message-dialog/message-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(
        private modalService: NzModalService,
        private _translateService: TranslateService
    ) {}

    async showConfirm(dialogData: any = {}, params: any = {}, options?: any): Promise<any> {
        const translatedData = await this.translateData(dialogData, params);
        let modal = this.modalService.create({
            nzContent: MposConfirmDialogComponent,
            nzData: {
                data: translatedData
            },
            nzFooter: null,
            nzClosable: false,
            ...options
        });

        return modal.afterClose.pipe(
            tap(() => {
                modal = null;
            })
        );
    }

    async translateData(messageKeys: any, params: any): Promise<any> {
        const translatedData: any = {};
        const keys = Object.keys(messageKeys);
        const values = keys.map((key) => messageKeys[key]);

        const translations = await this._translateService.get(values, params).toPromise();
        for (let i = 0; i < keys.length; i++) {
            translatedData[keys[i]] = translations[values[i]];
        }
        return translatedData;
    }

    async showMessage(dialogData: any = {}, params: any = {}, options?: any): Promise<any> {
        const translatedData = await this.translateData(dialogData, params);

        let modal = this.modalService.create({
            nzContent: MposMessageDialogComponent,
            nzData: {
                data: translatedData
            },
            nzFooter: null,
            nzClosable: false,
            ...options
        });

        return modal.afterClose
            .pipe(
                tap(() => {
                    modal = null;
                })
            )
            .toPromise();
    }
}
