import {Component, Inject, Input, OnInit} from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';

@Component({
    selector: 'mpos-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss']
})
export class MposMessageDialogComponent implements OnInit {
    data: any;

    dialogParams: any = {
        message: 'Please confirm you want to proceed...',
        title: 'Confirmation',
        confirmText: 'Confirm',
        cancelText: 'Cancel'
    };

    constructor(
        @Inject(NZ_MODAL_DATA) public modalData: any,
        public modal: NzModalRef
    ) {
        this.data = modalData.data;
    }

    ngOnInit(): void {
        this.dialogParams = {...this.dialogParams, ...this.data};
    }
}
