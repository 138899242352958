<div>
    <h2>{{ dialogParams.title }}</h2>
    <div class="di-text">
        {{ dialogParams.message }}
    </div>
    <div class="di-footer pt-24">
        <button nz-button id="button-show-confirm-ok" [nzType]="'primary'" class="mr-16" (click)="modal.destroy(true)">
            {{ dialogParams.confirmText }}
        </button>
        <button nz-button [nzType]="'default'" (click)="modal.destroy(false)">
            {{ dialogParams.cancelText }}
        </button>
    </div>
</div>
